#HIW {
  background: rgb(110, 100, 100);
  background: -moz-linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6e6464",endColorstr="#f0f1e3",GradientType=1);
}

#HIWTitle {
  text-align: center;
  font-size: 60px;
  color: black;
  font-weight: bold;
  padding-bottom: 30px;
}

.number {
  font-size: 18px;
  border-radius: 50%;
  background-color: darkslategray;
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

@media screen and (max-width: 1170px) {
  .number {
    height: 40px;
  }

  #title {
    font-size: 5vw;
  }

  #HIWTitle {
    font-size: 35px;
  }
}
