.ServicesHeader {
  text-align: center;
  font-size: 3.5vw;
  color: black;
  font-weight: bold;
  padding-bottom: 30px;
}

#areasOfExpertise {
  background: rgb(110, 100, 100);
  background: -moz-linear-gradient(
    180deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6e6464",endColorstr="#f0f1e3",GradientType=1);
}

#ServicesGrid {
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(8, auto);
  grid-gap: 5px;
  text-align: left;
  font-size: 1.3vw;
  padding-left: 25%;
  padding-right: 25%;
}

.elementTitle {
  font-size: 1.7vw;
  font-weight: bold;
}

.card {
  border: 1px solid black;
  border-radius: 30px;
  background-color: #e6b89d;
  padding: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  justify-content: center;
}

.card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.servicesElement {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10%;
  border-radius: 30px;
  background-color: aqua;
}

.servicesElement img {
  border-radius: 20px;
  margin: 2%;
}

.servicesText {
  display: flex;
  justify-content: center;
  margin: 0 2%;
  font-size: large;
}

@media screen and (max-width: 960px) {
  #ServicesGrid {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(8, auto);
    font-size: 4.8vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  .ServicesHeader {
    font-size: 35px;
  }

  .elementTitle {
    font-weight: normal;
    font-style: normal;
    font-size: 4vw;
  }
}
