.IntroWrapper {
  background-repeat: no-repeat;
  background-image: url("../../Pictures/screen_1664708069.jpg");
  background-position: top;
  width: 100%;
  height: 100vh;
  background-size: cover;
  color: #fff;
  font-size: 50px;
}

.Intro {
  position: absolute;
  top: 140px;
  left: 40px;
}

#citation {
  font-size: 40px;
  padding-left: 35px;
  font-style: italic;
}

@media screen and (max-width: 960px) {
  .IntroWrapper {
    background-repeat: no-repeat;
    background-image: url("../../Pictures/screen_1664708069.jpg");
    background-position: top;
    width: 100%;
    height: 90vh;
    background-size: cover;
    color: #fff;
    font-size: 40px;
  }
  .Intro {
    font-size: 5vw;
  }

  #citation {
    font-size: 3.4vw;
  }
}
