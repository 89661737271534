#Introduction {
  background: rgb(110, 100, 100);
  background: -moz-linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6e6464",endColorstr="#f0f1e3",GradientType=1);
}

.CVHeader {
  font-size: 60px;
  text-align: center;
  height: max-content;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  font-weight: bold;
}

#IntroductionContentWrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto;
  justify-content: center;
  padding-bottom: 10px;
}

#IntroductionContent {
  width: 90%;
  padding: 5%;
  font-size: 1.3em;
  justify-content: center;
  text-align: justify;
}

#red_dress {
  width: 85%;
  border-radius: 50px;
  align-content: center;
  justify-content: center;
  object-fit: cover;
}

#red_dress_mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .CVHeader {
    font-size: 35px;
  }

  #IntroductionContentWrapper {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
  }

  #red_dress {
    margin-left: 7.5%;
  }

  #IntroductionContent {
    font-size: 1em;
    text-align: justify;
  }
}
