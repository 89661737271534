/*ready*/

#logo {
  display: block;
  height: 75px;
  margin-left: 2rem;
}

.NavbarItems {
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border-bottom: 1px black solid;
  background: rgb(100, 90, 90);
  background: -moz-linear-gradient(
    270deg,
    rgba(120, 110, 110, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(120, 110, 110, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: linear-gradient(
    270deg,
    rgba(120, 110, 110, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#645a5a",endColorstr="#f0f1e3",GradientType=1);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  list-style: none;
  width: 100vw;
  justify-content: end;
  margin-right: 1rem;
}

.nav-links {
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 601;
}

.nav-links:hover {
  background: rgb(255, 255, 255);
  color: black;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.lang-btn {
  margin: 0 1rem;
  border-radius: 5px;
}

.lang-btn:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1060px) {
  .lang-div {
    padding: 2rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .lang-btn {
    margin: 0;
  }

  .lang-div:hover {
    background: white;
  }

  .menu-icon {
    display: flex;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 66px;
    left: -130%;
    z-index: 11;
  }

  .nav-menu.active {
    background: rgb(100, 90, 90);
    background: -moz-linear-gradient(
      270deg,
      rgba(120, 110, 110, 1) 0%,
      rgba(240, 241, 227, 1) 100%
    );
    background: -webkit-linear-gradient(
      270deg,
      rgba(120, 110, 110, 1) 0%,
      rgba(240, 241, 227, 1) 100%
    );
    background: linear-gradient(
      270deg,
      rgba(120, 110, 110, 1) 0%,
      rgba(240, 241, 227, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#645a5a",endColorstr="#f0f1e3",GradientType=1);
    left: -40px;
    transition: all 0.5s ease;
  }

  .nav-links {
    padding: 2rem 0;
    justify-content: center;
    display: flex;
  }

  .nav-links:hover {
    background: #fff;
    border-radius: 0;
    color: black;
  }

  .NavbarItems {
    justify-content: space-between;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 10px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}
