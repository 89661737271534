#Contact {
  background: rgb(110, 100, 100);
  background: -moz-linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6e6464",endColorstr="#f0f1e3",GradientType=1);
  display: flex;
  justify-content: space-around;
}

form {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: 1px solid black;
  background: white;
  padding: 2%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 0 5%;
  height: 70%;
  align-self: center;
}

#ContactDetails {
  padding: 0 5%;
  width: 50%;
  display: flex;
  flex-direction: column;
}

#map {
  width: 100%;
  height: 300px;
}

.ContactText {
  font-size: 20px;
  color: black;
  padding: 5px 0;
}

#ContactTitle {
  font-size: 60px;
  padding-bottom: 15px;
  font-weight: bold;
}

.ContactSubTitle {
  font-size: 23px;
}

#FormTitle {
  text-align: center;
  width: 100%;
  font-size: 30px;
  padding-bottom: 10px;
  font-weight: bold;
}

input {
  font-size: 15px;
  padding: 8px;
  margin: 2% 0;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #1565c0;
}

textarea {
  font-size: 15px;
  padding: 8px;
  margin: 2% 0;
  height: 70px;
  resize: none;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #1565c0;
}

#button {
  padding: 8px;
  margin: 2% 0;
  background-color: #e4a885;
  transition: ease-out 0.3s;
  border-radius: 6px;
  font-size: 20px;
}

#button:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  transition: ease-out 0.3s;
}

@media screen and (max-width: 900px) {
  #Contact {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    margin: 0 auto; /*doesnt work :(*/
  }

  #ContactTitle {
    font-size: 35px;
  }

  form {
    margin: 0;
    margin-top: 5%;
    width: 80vw;
    height: 90%;
    padding: 4%;
  }

  #ContactDetails {
    width: 100%;
    padding: 0;
  }

  #ContactTitle {
    text-align: center;
  }

  .ContactSubTitle {
    font-size: calc(100vw / 20);
  }

  .ContactText {
    font-size: calc(100vw / 22);
  }

  #map {
    width: 80%;
  }
}

.ContactText a {
  text-decoration: none;
  margin: 5px;
  border-radius: 5px;
}

.ContactText a:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  transition: ease-out 0.3s;
}
