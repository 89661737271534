.imgContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  grid-gap: 1%;
  width: 90%;
  margin: 1% 5%;
}

.desc {
  width: 40%;
  justify-content: center;
  font-size: 20px;
  margin: 0.5% 1%;
  text-align: justify;
}
.bg {
  background: rgb(110, 100, 100);
  background: -moz-linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6e6464",endColorstr="#f0f1e3",GradientType=1);
}

@media screen and (max-width: 1060px) {
  .imgContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    grid-gap: 1%;
    width: 90%;
    margin: 1% 5%;
  }

  .desc {
    width: 80%;
    justify-content: center;
    font-size: 20px;
    margin: 0.5% 1%;
    text-align: justify;
  }
}
