#Price {
  background: rgb(110, 100, 100);
  background: -moz-linear-gradient(
    180deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6e6464",endColorstr="#f0f1e3",GradientType=1);

  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 14%;
  padding-right: 14%;
}

#PriceTitle {
  text-align: center;
  font-size: 60px;
  color: black;
  padding-bottom: 2%;
  font-weight: bold;
}

#PriceText {
  text-align: justify;
  font-size: 25px;
  font-weight: 550;
  color: black;
  margin-left: 15%;
  width: 70%;
}

#PriceText li {
  margin-top: 5px;
}

#PriceImg {
  float: right;
  border-radius: 20px;
  margin-left: 20px;
}

#PriceImgMobile {
  display: none;
}
@media screen and (max-width: 960px) {
  #PriceText {
    width: 90%;
    margin-left: 5%;
    font-size: 22px;
    text-align: left;
  }
}
