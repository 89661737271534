#Impress {
  padding: 3% 7% 2% 7%;
  background: rgb(110, 100, 100);
  background: -moz-linear-gradient(
    180deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(110, 100, 100, 1) 0%,
    rgba(240, 241, 227, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6e6464",endColorstr="#f0f1e3",GradientType=1);
}
